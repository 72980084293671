import Vue from "vue"
import Vuetify from "vuetify/lib"
import it from "vuetify/es5/locale/it"
import { TiptapVuetifyPlugin } from "tiptap-vuetify"
import { loadTenantConfig } from "@/plugins/multitenant"

Vue.use(Vuetify)

const tenantConfig = loadTenantConfig()

const vuetify = new Vuetify({
	lang: {
		locales: { it },
		current: "it",
	},
	icons: {
		iconfont: "fa",
		values: {
			"repubblica-italiana": "repubblica-italiana",
			"clear": "fas fa-times"
		}
	},
	...tenantConfig.vuetify
})

Vue.use(TiptapVuetifyPlugin, {
	vuetify,
	iconsGroup: "fa"
})

export default vuetify;
