import store from "../store"
import router from "../router"
import axios from "axios"
import { isArray, isString, forEach, split, get, isNil, isEqual, reduce, isEmpty } from "lodash"
import * as _ from "lodash"

function goToLogin() { if(router.currentRoute.name !== "login") router.push({ name: "login" }) }

function goToHome() { if(router.currentRoute.name !== "home") router.push({ name: "home" }) }

function goToRefreshPassword(username) {
	if(router.currentRoute.name !== "refresh-password") router.push({ name: "refresh-password", params: { username }, query: router.currentRoute.query })
}

export async function login(username, password) {
	try {
		const response = await axios.post("/login", { username, password }, { secured: false })
		const { access_token: access, refresh_token: refresh } = response.data
		store.commit("setToken", { access, refresh })
		await fetchUserInfo(access)
	} catch(response) {
		console.error("login error", response)
		if(response.data.error === "credentials_expired") {
			console.debug("credentials was expired, go to refresh password")
			goToRefreshPassword(username)
			return Promise.reject(response)
		} else store.commit("setLoginError", true)
	}
}

export async function getRefreshToken(accessToken) {
	const config = {}
	if(!isNil(accessToken)) {
		config.secured = false
		config.headers = { Authorization: `Bearer ${accessToken}` }
	}
	const response = await axios.get("/refresh-token", config)
	return response.data.refresh_token
}

export async function getRefreshTokenAndAuthenticate(accessToken) {
	try {
		const refresh = await getRefreshToken(accessToken)
		store.commit("setToken", {access: accessToken, refresh})
		await fetchUserInfo(accessToken)
		return store.getters.isAuthenticated
	} catch(e) {
		console.error("Unexpected error", e)
		return false
	}
}

export async function oauthCallback(to, from, next) {
	console.debug("oauth callback", to)
	if(!!to.query.token) {
		const access = to.query.token
		const refresh = await getRefreshToken(access)
		store.commit("setToken", { access, refresh })
		await fetchUserInfo(access)
		const redirectTo = isNil(to.query.p) ? { name: "home" } : atob(to.query.p)
		next(redirectTo)
	} else next({ name: "login", params: { error: true } })
}

export async function loginAs(username) {
	console.debug("try to login as", username)
	try {
		const user = store.getters.userInfo
		const response = await axios.post("/login-as", { username })
		const { access_token: access, refresh_token: refresh } = response.data
		store.commit("loginAs", { access, refresh, user })
		await fetchUserInfo(access)
	} catch(e) { store.commit("setLoginAsError", true) }
}

export async function loginAsCallback(to, from, next) {
	console.debug("login as callback", to)
	if(!!to.params.username) {
		const username = atob(to.params.username)
		await loginAs(username)
		next({ name: "home" })
	} else next(false)
}

export async function refreshPassword(username, password, nuovaPassword) {
	try {
		await axios.post("/utenti/change-password", {username, password, nuovaPassword})
		await login(username, nuovaPassword)
	} catch(e) { console.error("errore change password", e, e.response) }
}

async function refresh(config) {
	try {
		const response = await axios.post("/refresh", { refreshToken: store.getters.refreshToken }, { secured: false })
		const token = response.data
		store.commit("setToken", { access: token.access_token, refresh: token.refresh_token })
		return await axios(config)
	} catch(e) {
		console.error("Refresh error", e)
		goToLogin()
	}
}

export function parseRolesArray(...roles) {
	return reduce(roles, (parsed, role) => {
		if(isArray(role)) parsed.push(...parseRolesArray(...role))
		else if(isString(role)) forEach(split(role, /[, ]/), r => {
			if(!isNil(r) && !isEmpty(r)) parsed.push(r)
		})
		return parsed
	}, [])
}

export function beforeRouteEnter(to, from, next) {
	const needAuthentication = to.matched.find(route => route.meta.isAuthenticated)
	if(!_.isNil(needAuthentication)) {
		console.debug("need authentication to access route", to.fullPath)
		if(!store.getters.isAuthenticated) {
			console.warn("user is not authenticated, redirect to login")
			next({ name: "login", query: { p: btoa(to.fullPath) }})
			return
		}
		const needRoles = to.matched.find(route => route.meta.roles)
		if(!_.isNil(needRoles)) {
			console.debug("need roles to access route", needRoles.meta.roles)
			if(!store.getters.hasAnyRoles(needRoles.meta.roles)) {
				console.debug("user is not authorized, redirect to unauthorized")
				next({ name: "unauthorized" })
				return
			}
		}
	}
	next()
}

export async function logout() {
	//while(store.getters.isAuthenticated) store.commit("logout")
	goToLogin()
	//await store.dispatch("logout")
}

export async function logoutFrom() {
	await store.dispatch("logoutFrom")
	if(store.getters.isAuthenticated) goToHome()
	else goToLogin()
}

export async function logoutToOriginal() {
	/*const utenteOriginale = store.getters.utenteOriginale
	while(store.getters.utente.id !== utenteOriginale.id) store.commit("logout")*/
	await store.dispatch("logoutToOriginal")
	if(store.getters.isAuthenticated) goToHome()
	else goToLogin()
}

export function setBearerToken(config) {
	const isSecured = config.secured !== false
	console.debug("set bearer token", isSecured)
	if(isSecured) {
		const token = store.getters.accessToken
		if (!!token) config.headers["Authorization"] = "Bearer " + token
	}
	return config
}

export function checkAuthentication(error) {
	console.debug("axios security check")
	const response = get(error, "response")
	if(!isNil(response)) {
		if(response.status === 401) {
			if(store.getters.isRefreshable) return refresh(error.config)
			//else if(!store.getters.isAuthenticated) goToLogin()
		}
		return Promise.reject(response)
	}
	return Promise.reject(error)
}

export async function fetchUserInfo(accessToken) {
	try {
		const config = {
			secured: false,
			headers: { Authorization: `Bearer ${accessToken}` }
		}
		const response = await axios.get("/utenti/info", config)
		const userInfo = get(response, "data")
		if(!isNil(userInfo)) store.commit("setUserInfo", userInfo)
	} catch(e) {
		console.error("Error reading user info", e)
		goToLogin()
	}
}