<script>
	import { VIcon } from "vuetify/lib"
	const NO_ORDER = process.env.VUE_APP_NO_ORDER
	const ASC_ORDER = process.env.VUE_APP_ASC_ORDER
	const DESC_ORDER = process.env.VUE_APP_DESC_ORDER

	export default {
		name: "ui-table-header",
		components: { VIcon },
		props: {
			column: {
				type: Object,
				required: true
			},
			sortIcon: {
				type: String,
				default: "fas fa-angle-up"
			},
			sort: String,
			order: {
				type: String,
				default: ""
			},
			fixedLayout: Boolean
		},
		computed: {
			prop() { return this.column.sortOn },
			sortable() { return this.column.sortable },
			isCurrentSort() { return this.sort === this.prop },
			sorted() { return this.sortable && this.order !== NO_ORDER && this.isCurrentSort },
			isAsc() { return this.sorted && this.order === ASC_ORDER },
			isDesc() { return this.sorted && this.order === DESC_ORDER },
			classes() { return this.column.classes },
			width() { return this.column.columnWidth },
			breakSpace() { return this.column.headerBreakSpace }
		},
		methods: {
			onSort() {
				if (this.sortable) {
					const current = this.isCurrentSort ? this.order : NO_ORDER
					const order = current === "" ? ASC_ORDER : current === ASC_ORDER ? DESC_ORDER : NO_ORDER
					this.$emit("onSort", {prop: this.prop, order: order, old: current})
				}
			},
			genSortIcon(h) {
				return h("v-icon", null, `${this.sortIcon} mx-1`)
			}
		},
		render(h) {
			const attrs = {}
			const rowspan = this.column.getRowspan(this.item), colspan = this.column.getColspan(this.item)
			if(!this._.isNil(rowspan)) attrs.rowspan = rowspan
			if(!this._.isNil(colspan)) attrs.colspan = colspan
			const headerData = {
				attrs,
				class: {
					"ui-table-header": true,
					...this.classes
				}
			}
			if (!!this.width) headerData.style = {width: this.width}
			const children = []
			const headerClass = {
				"d-block": !this.sortable,
				"d-flex justify-center": this.sortable,
				"sortable": this.sortable,
				"sorted": this.sorted,
				"primary--text": this.sorted,
				"asc": this.isAsc,
				"desc": this.isDesc,
				"white-space--break-spaces": this.breakSpace
			}, sortIcon = `${this.sortIcon} mx-1`
			if (this.column.hasCustomHeader()) children.push(this.column.renderCustomHeader({ sort: this.onSort.bind(this), headerClass, sortIcon }))
			else {
				const text = []
				const data = {
					class: headerClass
				}
				if (this.sortable) {
					text.push(this.genSortIcon(h))
					data.on = {click: this.onSort}
				}
				text.push(this.column.renderHeaderText())
				children.push(h("span", data, text))
			}
			if (this.column.hasHeaderExtension()) children.push(this.column.renderHeaderExtension())
			return h("th", headerData, children)
		}
	}
</script>

<style lang="scss" scoped>
	.ui-table-header {
		span.sortable {
			cursor: pointer;

			.v-icon {
				vertical-align: text-bottom;
				display: inline-block;
				font-size: 16px;
			}

			&:hover:not(.sorted) .v-icon {
				opacity: 0.5 !important;
			}

			&:not(.sorted) .v-icon {
				opacity: 0;
			}

			&.sorted .v-icon {
				opacity: 1;
				-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
				transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
			}

			&.sorted.desc .v-icon {
				-webkit-transform: rotate(-180deg);
				transform: rotate(-180deg);
			}
		}
	}
</style>