import jwt_decode from "jwt-decode"
import { parseRolesArray } from "@/libs/security"
import { reduce, startsWith, pick, isNil } from "lodash"

/*function buildUser(token) {
	if(token === "") return {}
	const jwt = jwt_decode(token)
	return {
		id: jwt.id,
		username: jwt.sub,
		nominativo: jwt.nominativo,
		gruppo: jwt.gruppo,
		email: jwt.email,
		authorities: jwt.authorities
	}
}*/

function buildUser(userInfo) {
	return pick(userInfo, ["id", "username", "nominativo", "gruppo", "email"])
}

const token = { access: "", refresh: "" }

const errors = { login: false, loginAs: false }

export default {
	state: {
		loginAs: [],
		token: { ...token },
		errors: { ...errors }
	},
	getters: {
		accessToken: state => state.token.access,
		refreshToken: state => state.token.refresh,
		isAuthenticated: (state, getters) => !!getters.accessToken,
		isImpersonated: state => state.loginAs.length > 0,
		isRefreshable: (state, getters) => !!getters.refreshToken,
		isMultipleImpersonated: state => state.loginAs.length > 1,
		jwt: (state, getters) => getters.isAuthenticated ? jwt_decode(getters.accessToken) : {},
		authorities: (state, getters) => {
			if(getters.isAuthenticated) return getters.userInfo.authorities || []
			else return []
		},
		utente: (state, getters) => buildUser(getters.userInfo),
		agencyRoles: (state, getters) => getters.userInfo?.agencyRoles,
		accessoOriginale: (state, getters) => getters.isImpersonated ? state.loginAs[0] : null,
		utenteOriginale: (state, getters) => getters.isImpersonated ? buildUser(getters.accessoOriginale.user) : null,
		accessoPrecedente: (state, getters) => getters.isImpersonated ? state.loginAs[state.loginAs.length - 1] : null,
		utentePrecedente: (state, getters) => getters.isImpersonated ? buildUser(getters.accessoPrecedente.user) : null,
		hasAnyRoles: (state, getters) => (...roles) => {
			const authorities = getters.authorities
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(authorities.indexOf(role) >= 0) return true
				return check
			}, false)
		},
		hasAnyParents: (state, getters) => (parent, ...roles) => {
			const roleDetails = getters.userInfo?.roleDetails
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(roleDetails && roleDetails[role] && roleDetails[role].parent && roleDetails[role].parent === parent) return true
				return check
			}, false);
		},
		hasNoAnyParents: (state, getters) => (...roles) => {
			const roleDetails = getters.userInfo?.roleDetails
			return reduce(parseRolesArray(...roles), (check, role) => {
				if(roleDetails && roleDetails[role] && roleDetails[role].parent) return check
				return true
			}, false);
		},
		loginError: state => state.errors.login,
		loginAsError: state => state.errors.loginAs
	},
	mutations: {
		setToken: (state, token) => state.token = Object.assign({}, state.token, token),
		loginAs: (state, { access, refresh, user }) => {
			console.debug("commit login as", user)
			const currentToken = state.token
			const newToken = { access, refresh }
			state.loginAs.push({ token: currentToken, user })
			state.token = newToken
		},
		logoutTo: (state, impersonateToken) => {
			if (isNil(impersonateToken)) {
				state.token = {...token}
				state.loginAs = []
			} else {
				let stackFixed = false
				do {
					const accessoPrecedente = state.loginAs.pop()
					stackFixed = isNil(accessoPrecedente) || accessoPrecedente.token === impersonateToken
				} while(!stackFixed)
				state.token = impersonateToken
			}
		},
		setLoginError: (state, value) => state.errors.login = value,
		setLoginAsError: (state, value) => state.errors.loginAs = value,
		clear: state => {
			state.loginAs = []
			state.token =  { ...token }
			state.errors = { ...errors }
		}
	},
	actions: {
		logout({ commit }) { commit("clear") },
		logoutFrom({ commit, dispatch, getters }) {
			if(getters.isImpersonated) {
				const accessoPrecedente = getters.accessoPrecedente
				console.debug("dispatch logout from", accessoPrecedente)
				commit("logoutTo", accessoPrecedente.token)
				commit("setUserInfo", accessoPrecedente.user)
			} else dispatch("logout")
		},
		logoutToOriginal({ commit, dispatch, getters }) {
			if(getters.isImpersonated) {
				const accessoOriginale = getters.accessoOriginale
				console.debug("dispatch logout to original", accessoOriginale)
				commit("logoutTo", accessoOriginale.token)
				commit("setUserInfo", accessoOriginale.user)
			} else dispatch("logout")
		}
	}
}