import VueAxios from "vue-axios"
import axios from "axios"
import Vue from "vue"
import qs from "qs"
import { setBearerToken, checkAuthentication } from "@/libs/security"
import { checkMaintenance } from "@/libs/maintenance"
import { isNil, get } from "lodash"
import store from "@/store"

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}/api`
axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" })

axios.interceptors.request.use(setBearerToken)
axios.interceptors.request.use(config => {
	const locale = store.getters.locale || process.env.VUE_APP_DEFAULT_LOCALE
	if(!!locale) config.headers[process.env.VUE_APP_LOCALE_HEADER] = locale
	else console.warn("locale not found")
	return config
})
axios.interceptors.response.use(response => response, checkMaintenance)
axios.interceptors.response.use(response => response, checkAuthentication)
axios.interceptors.response.use(response => response, error => {
	const response = get(error, "response")
	if(!isNil(response)) {
		switch(response.status) {
			case 400:
			case 500:
				store.commit("setException", response.data.error_description)
				break
		}
		return Promise.reject(response)
	}
	return Promise.reject(error)
})

Vue.use(VueAxios, axios)