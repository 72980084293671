import Vue from "vue"

// Fields
import UiField from "@/components/fields/ui-field"
import UiSelect from "@/components/fields/ui-select"
import UiRemoteSelect from "@/components/fields/ui-remote-select"
import UiFileField from "@/components/fields/ui-file-field"
import UiImageField from "@/components/fields/ui-image-field"
import UiDateField from "@/components/fields/ui-date-field"
import UiOutputField from "@/components/fields/ui-output-field"
import UiSearchField from "@/components/fields/ui-search-field"
import UiEmailLink from "@/components/fields/ui-email-link"
import UiPasswordField from "@/components/fields/ui-password-field"
import UiMinmaxDecimalField from "@/components/fields/ui-minmax-decimal-field"
import UiSelectList from "@/components/fields/ui-select-list"
import UiEmailField from "@/components/fields/ui-email-field.vue"
import UiDatetimeField from "@/components/fields/ui-datetime-field.vue"
import UiPeriodField from "@/components/fields/ui-period-field"

// Table
import UiTable from "@/components/table/ui-table"
import UiRemoteTable from "@/components/table/ui-remote-table"
import UiDraggableTable from "@/components/table/ui-draggable-table"
import UiEditTable from "@/components/table/ui-edit-table"
import UiTableColumn from "@/components/table/ui-table-column"

// Misc
import UiPageLoader from "@/components/ui-page-loader"
import UiVerticalTabs from "@/components/ui-vertical-tabs"
import UiErrorIcon from "@/components/ui-error-icon"
import UiPageForm from "@/components/ui-page-form"
import ValueProvider from "@/components/value-provider"
import UiTimeoutBtn from "@/components/ui-timeout-btn"
import UiPagination from "@/components/commons/ui-pagination"

// Vuetify
import { VContainer, VRow, VCol, VDivider, VSlideYTransition, VTooltip, VBtn, VIcon } from "vuetify/lib"

Vue.component(UiField.name, UiField)
Vue.component(UiSelect.name, UiSelect)
Vue.component(UiRemoteSelect.name, UiRemoteSelect)
Vue.component(UiFileField.name, UiFileField)
Vue.component(UiImageField.name, UiImageField)
Vue.component(UiDateField.name, UiDateField)
Vue.component(UiOutputField.name, UiOutputField)
Vue.component(UiSearchField.name, UiSearchField)
Vue.component(UiEmailLink.name, UiEmailLink)
Vue.component(UiPasswordField.name, UiPasswordField)
Vue.component(UiMinmaxDecimalField.name, UiMinmaxDecimalField)
Vue.component(UiSelectList.name, UiSelectList)
Vue.component(UiEmailField.name, UiEmailField)
Vue.component(UiDatetimeField.name, UiDatetimeField)
Vue.component(UiPeriodField.name, UiPeriodField)

Vue.component(UiTable.name, UiTable)
Vue.component(UiRemoteTable.name, UiRemoteTable)
Vue.component(UiDraggableTable.name, UiDraggableTable)
Vue.component(UiEditTable.name, UiEditTable)
Vue.component(UiTableColumn.name, UiTableColumn)

Vue.component(UiPageLoader.name, UiPageLoader)
Vue.component(UiVerticalTabs.name, UiVerticalTabs)
Vue.component(UiErrorIcon.name, UiErrorIcon)
Vue.component(UiPageForm.name, UiPageForm)
Vue.component(ValueProvider.name, ValueProvider)
Vue.component(UiTimeoutBtn.name, UiTimeoutBtn)
Vue.component(UiPagination.name, UiPagination)

Vue.component("VContainer", VContainer)
Vue.component("VRow", VRow)
Vue.component("VCol", VCol)
Vue.component("VDivider", VDivider)
Vue.component("VSlideYTransition", VSlideYTransition)
Vue.component("VTooltip", VTooltip)
Vue.component("VBtn", VBtn)
Vue.component("VIcon", VIcon)