<template>
	<v-container fluid ui-table-pagination>
		<v-row no-gutters align="center" justify="space-between" :class="$vuetify.breakpoint.xsOnly && 'flex-column-reverse'">
			<v-col cols="12" sm="4" class="d-flex align-center" :class="$vuetify.breakpoint.xsOnly && 'justify-center mt-3'">
				<span class="text-capitalize">{{$t('default.pagination.max.show')}}</span>
				<ui-field type="select" v-model="maxItems" hide-details :items="rowsPerPage" class="mx-2 pt-0 pb-1 ui-table-max" :menu-props="{ minWidth: 100 }"/>
				<span>{{$t('default.pagination.max.of', { item: $tc(maxSuffix, totalItems)})}}</span>
			</v-col>
			<v-col cols="12" sm="8" class="d-flex align-center justify-end" :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'">
				<v-btn small :color="color" :dark="!prevDisabled" class="prev mb-0 mr-2" @click="goToFirstPage" :disabled="prevDisabled">
					<v-icon>fas fa-angle-double-left</v-icon>
				</v-btn>
				<v-btn small :color="color" :dark="!prevDisabled" class="prev mb-0 mr-2" @click="goToPrevPage" :disabled="prevDisabled">
					<v-icon>fas fa-angle-left</v-icon>
				</v-btn>
				<ui-field type="select" v-model="tablePage" hide-details :items="pages" class="mx-2 pt-0 pb-1 ui-table-page" :menu-props="{ minWidth: 150 }" :class="$vuetify.breakpoint.xsOnly && 'mobile'">
					<template #selection="{ item }" v-if="$vuetify.breakpoint.xsOnly">
						<span class="v-select__selection--comma">{{item.value}}</span>
					</template>
				</ui-field>
				<v-btn small :color="color" :dark="!nextDisabled" class="next mb-0 ml-2" @click="goToNextPage" :disabled="nextDisabled">
					<v-icon>fas fa-angle-right</v-icon>
				</v-btn>
				<v-btn small :color="color" :dark="!nextDisabled" class="next mb-0 ml-2" @click="goToLastPage" :disabled="nextDisabled">
					<v-icon>fas fa-angle-double-right</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

const DEFAULT_ROWS_PER_PAGE = JSON.parse(process.env.VUE_APP_DEFAULT_ROWS_PER_PAGE)

export default {
	name: "ui-table-pagination",
	props: {
		max: { type: Number, default: 20 },
		page: { type: Number, default: 1 },
		totalItems: { type: Number, default: 0 },
		color: { type: String, default: "primary" },
		maxSuffix: { type: String, default: "default.pagination.max.item"}
	},
	computed: {
		maxItems: {
			get() { return this.max },
			set(max) { this.updateMax(max) }
		},
		tablePage: {
			get() { return this.page },
			set(page) { this.updatePage(page) }
		},
		rowsPerPage() {
			const maxes = DEFAULT_ROWS_PER_PAGE
			if (this.max < this.totalItems && !this._.includes(maxes, this.max)) maxes.push(this.max)
			return this._.sortBy(maxes)
		},
		pages() {
			const pages = this.totalItems < this.max ? [1] : this._.times(Math.ceil(this.totalItems / Math.abs(this.max)), n => n + 1)
			return this._.map(pages, page => ({ value: page, text: this.$t("default.pagination.page", { page, pages: pages.length }) }))
		},
		maxPage() {
			const maxPageItem = this._.maxBy(this.pages, "value")
			if (this._.isUndefined(maxPageItem)) return 1
			else return maxPageItem.value
		},
		prevDisabled() { return this.page === 1 },
		nextDisabled() { return this.page === this.maxPage }
	},
	methods: {
		updateMax(max) { if(max !== this.max) this.updatePagination(max, 1) },
		updatePage(page) { if(page !== this.page) this.updatePagination(this.max, page) },
		updatePagination(max, page) {
			console.debug("ui-table-pagination updatePagination", page, max)
			this.$emit("update:pagination", { page, max })
		},
		goToFirstPage() { if(!this.prevDisabled) this.updatePage(1) },
		goToPrevPage() { if(!this.prevDisabled) this.updatePage(this.page - 1) },
		goToNextPage() { if(!this.nextDisabled) this.updatePage(this.page + 1) },
		goToLastPage() { if(!this.nextDisabled) this.updatePage(this.maxPage) }
	}
}
</script>

<style lang="scss" scoped>
	.ui-table-pagination {
		font-size: 12px;
		.ui-table-max, .ui-table-page { flex: 0 1 0; }
		.ui-table-page:not(.mobile) { min-width: 150px; }
		.prev, .next { min-width: 40px; }
		::v-deep .v-select {
			input { font-size: 12px; }
			.v-select__selections .v-select__selection--comma { font-size: 12px; }
		}
	}
</style>