import SockJS from "sockjs-client"
import Stomp from "webstomp-client"
import eventBus from "@/libs/global-event-bus"
import { isNil, isFunction, get } from "lodash"

class NaisWebsocket {

	#isConnecting = false
	#isConnected = false

	set connecting(value) {
		this.#isConnecting = value
		eventBus.$emit("websocket-connecting", this.#isConnecting)
	}

	get connecting() { return this.#isConnecting }

	set connected(value) {
		this.#isConnected = value
		eventBus.$emit("websocket-connected", this)
	}

	get connected() { return this.#isConnected }

	connect(accessToken, headers = {}) {
		console.debug("NaisWebsocket connecting...")
		this.connecting = true
		this.socket = new SockJS(`${process.env.VUE_APP_BACKEND_URL}/ws/stomp?access_token=${accessToken}`)
		this.client = Stomp.over(this.socket, { debug: false })
		return new Promise(resolve => {
			if(this.connected) {
				resolve(this)
				return
			}
			this.client.connect(headers, frame => {
				console.debug("NaisWebsocket connected", frame)
				this.connecting = false
				this.connected = true
				resolve(this)
			}, async error => {
				await this.disconnect(true, headers)
				console.debug("NaisWebsocket connection error", error)
				await this.#reconnect(accessToken, headers)
			})
		})
	}

	#reconnect(accessToken, headers) {
		console.debug("NaisWebsocket reconnecting...")
		return new Promise(resolve => {
			const reconnect = setTimeout(async () => {
				await this.connect(accessToken, headers)
				console.debug("NaisWebsocket reconnected")
				clearTimeout(reconnect)
				this.connecting = false
				resolve(true)
			}, 5000)
		})
	}

	disconnect(quiet = true, headers = {}) {
		return new Promise((resolve, reject) => {
			if(this.connected) {
				this.client.disconnect(() => {
					console.debug("NaisWebsocket disconnected")
					this.connected = false
					resolve(this)
				}, headers)
			} else if(quiet) resolve(this)
			else reject("NaisWebsocket is not connected")
		})
	}

	subscribe(queue, messageHandler, alwaysAck = true, headers = {}) {
		if(this.connected) {
			const subscription = this.client.subscribe(queue, frame => {
				const payload = JSON.parse(frame.body)
				if(isFunction(messageHandler)) messageHandler(payload, frame)
				if(alwaysAck) frame.ack()
			}, Object.assign({}, headers, { ack: "client" }))
			console.debug("NaisWebsocket queue subscribed", queue, subscription.id)
			return subscription
		}
	}

	subscribeUser(queue, messageHandler, alwaysAck = true, headers = {}) {
		const userQueue = `/user${queue}`
		return this.subscribe(userQueue, messageHandler, alwaysAck, headers)
	}

	send(destination, payload, headers = {}) {
		if(this.connected) {
			this.client.send(destination, JSON.stringify(payload), headers)
			console.debug("NaisWebsocket message sent", destination, payload)
		}
	}

}

export default new NaisWebsocket()