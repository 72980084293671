import ArrayFormatterMixin from "./array-formatter-mixin"
import { isFunction } from "lodash"
import { parseApi } from "@/libs/utils"

function getRemoteApi(formatApi, item) {
	if(isFunction(formatApi)) return parseApi(formatApi(item))
	else return parseApi(formatApi)
}

export default {
	mixins: [ArrayFormatterMixin],
	props: {
		formatApi: [String, Object, Function],
		itemTranslate: String
	},
	data() {
		return {
			translating: false
		}
	},
	methods: {
		async remoteFormat(value) {
			try {
				this.translating = true
				const response = await this.axios.request(getRemoteApi(this.formatApi, value))
				const data = response.data
				if(this._.isString(data)) return data
				else if(!this._.isNil(this.itemTranslate)) return this._.get(data, this.itemTranslate, "")
				else throw new Error("Traduzione remota fallita")
			} finally { this.translating = false }
		},
		async remoteTranslate(value) {
			if(this._.isArray(value)) return await this.asyncFormatArray(value, this.remoteFormat)
			else return await this.remoteFormat(value)
		}
	}
}