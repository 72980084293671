import i18n from "@/plugins/i18n"
import { isNil, isString, isArray, isPlainObject, size, isNumber } from "lodash"

/**
 * Funzione che restituisce la stringa localizzata a partire da una chiave.
 * <p>Se la chiave passata è una String, allora verrà utilizzata per chiamare il metodo {@link VueI18n.t}
 * <p>Altrimenti si può passare un Array opportunamente costruito:
 * <ul>
 *     <li>[String, Number]: la chiave verrà scomposta e passata al metodo {@link VueI18n.tc}, il primo elemento è la chiave da tradurre, il secondo elemento è la quantità
 *     <li>[String, Object]: la chiave verrà scomposta e passata al metodo {@link VueI18n.t}, il primo elemento è la chiave da tradurre, il secondo elemento sono i parametri da interpolare nella stringa localizzata
 *     <li>[String, Number, Object]: la chiave verrà scomposta e passata al metodo {@link VueI18n.tc}, il primo elemento è la chiave da tradurre, il secondo elemento è la quantità e il terzo elemento sono i parametri da interpolare nella stringa localizzata
 *
 * @param key la chiave da localizzare
 * @returns {string} la chiave localizzata
 */
export function getTranslation(key) {
	console.debug("get translation of", key)
	if(isNil(key)) return key
	if(isString(key)) return i18n.t(key)
	if(isArray(key) && size(key) > 1) {
		const realKey = key[0]
		if(isString(realKey)) {
			const firstArg = key[1]
			if(isPlainObject(firstArg)) return i18n.t(realKey, firstArg)
			if(isNumber(firstArg)) {
				if(size(key) > 2) {
					const secondArg = key[2]
					if(isPlainObject(secondArg)) return i18n.tc(realKey, firstArg, secondArg)
				} else return i18n.tc(realKey, firstArg)
			}
		}
	}
	throw new Error("i18n key was invalid")
}