<template lang="pug">
div(class="ui-minmax-decimal-field")
	v-menu(v-model="showPicker", :close-on-content-click="false", transition="scale-transition", offset-y, max-width="290px", min-width="290px")
		template(#activator="{ on }")
			slot(name="activator", v-bind="{ on, readonly, rangeFormatted, icon, attrs: textAttrs }")
				ui-output-field(v-show="readonly", :value="rangeFormatted", :prepend-icon="icon", v-bind="readonlyAttrs")
				v-text-field(v-show="!readonly", :value="rangeFormatted", readonly, :prepend-icon="icon", v-bind="textAttrs", v-on="{ ...on, ...clearEvent }")
		validation-observer(#default="{ handleSubmit }", slim, ref="form-minmax")
			v-card
				v-card-text
					v-row(dense)
						v-col(cols="12")
							ui-field(type="number", :step="step", v-model.number="rangeMinimo", label="Minimo", :name="lazyNames.minimo", :rules="rulesMinimo", :prepend-icon="icon")
						v-col(cols="12")
							ui-field(type="number", :step="step", v-model.number="rangeMassimo", label="Massimo", :name="lazyNames.massimo", :rules="rulesMassimo", :prepend-icon="icon")
				v-divider
				v-card-actions
					v-btn(text, color="secondary", @click="closePicker") Annulla
					v-spacer
					v-btn(text, color="primary", @click="handleSubmit(lazyInput)") Conferma
</template>

<script>
import { lazyModelMixinFactory } from "@/mixins/lazy-model-mixin"
import { isNil } from "lodash"
import { formatDecimal } from "@/plugins/numeral"

export function rangeFormatterFactory(unit, decimals = 2) {
	function addUnit(value) {
		const formattedValue = formatDecimal(value, decimals)
		if(!isNil(unit)) return `${formattedValue}`.concat(unit)
		return formattedValue
	}
	return function(minimo, massimo) {
		if(isNil(minimo) && isNil(massimo)) return ""
		if(isNil(massimo)) return `da ${addUnit(minimo)}`
		if(isNil(minimo)) return `fino a ${addUnit(massimo)}`
		return `da ${addUnit(minimo)} a ${addUnit(massimo)}`
	}
}

export const defaultRangeFormatter = rangeFormatterFactory()

export default {
	name: "ui-minmax-decimal-field",
	mixins: [lazyModelMixinFactory(Object, "range")],
	props: {
		icon: String,
		rangeFormatter: Function,
		unit: String,
		clearable: Boolean,
		min: Number,
		max: Number,
		boundary: Object,
		noRangeText: { type: String, default: "nessun range configurato" },
		readonly: Boolean,
		nameMinimo: { type: String, default: "minimo" },
		nameMassimo: { type: String, default: "massimo" },
		step: { type: Number, default: 0.01 }
	},
	data() {
		return {
			showPicker: false,
			names: {
				minimo: "",
				massimo: ""
			}
		}
	},
	computed: {
		isClearable() { return this.clearable },
		minimo() { return this._.get(this.value, "minimo") },
		massimo() { return this._.get(this.value, "massimo") },
		rangeFormatted() {
			if(this._.isNil(this.minimo) && this._.isNil(this.massimo)) return this.noRangeText
			if(this._.isNil(this.rangeFormatter) && this._.isNil(this.unit)) return defaultRangeFormatter(this.minimo, this.massimo)
			if(this._.isNil(this.rangeFormatter)) return rangeFormatterFactory(this.unit, this.decimals)(this.minimo, this.massimo)
			return this.rangeFormatter(this.minimo, this.massimo)
		},
		textAttrs() { return Object.assign({ dense: true }, this.$attrs, this.clearButton) },
		readonlyAttrs() {
			const attrs = Object.assign({}, this.textAttrs)
			if(this._.hasIn(attrs, "rules")) delete attrs.rules
			return attrs
		},
		rulesMinimo() {
			const rules = { required: !!this.range, max_value: this._.get(this.range, "massimo", this.massimo) }
			if(!this._.isNil(this.min)) rules.min_value = this.min
			else if(!this._.isNil(this.boundary)) rules.min_value = this._.get(this.boundary, "minimo")
			return rules
		},
		rulesMassimo() {
			const rules = { required: !!this.range, min_value: this._.get(this.range, "minimo", this.minimo) }
			if(!this._.isNil(this.max)) rules.max_value = this.max
			else if(!this._.isNil(this.boundary)) rules.max_value = this._.get(this.boundary, "massimo")
			return rules
		},
		rangeMinimo: {
			get() { if(!!this.range) return this.range.minimo },
			set(minimo) {
				if(this._.isNil(this.range)) this.range = {}
				this.$set(this.range, "minimo", minimo)
			}
		},
		rangeMassimo: {
			get() { if(!!this.range) return this.range.massimo },
			set(massimo) {
				if(this._.isNil(this.range)) this.range = {}
				this.$set(this.range, "massimo", massimo)
			}
		},
		lazyNames: {
			get() { return this.names },
			set(value) {
				const minimo = this._.get(value, "minimo")
				const massimo = this._.get(value, "massimo")
				this.names = { minimo, massimo }
			}
		},
		decimals() {
			switch(this.step) {
				case 1: return 0
				case 0.1: return 1
				case 0.01: return 2
			}
		}
	},
	methods: {
		closePicker() {
			this.showPicker = false
			this.$nextTick(() => {
				this.$refs["form-minmax"].reset()
				this.initModel(true)
			})
		},
		onInput() { this.closePicker() }
	},
	watch: {
		nameMinimo: {
			handler(value) { this.names.minimo = value },
			immediate: true
		},
		nameMassimo: {
			handler(value) { this.names.massimo = value },
			immediate: true
		}
	}
}
</script>