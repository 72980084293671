import Vue from "vue"
import Router from "vue-router"
import { oauthCallback, beforeRouteEnter, loginAsCallback } from "@/libs/security"
import { loadTenantConfig } from "@/plugins/multitenant"

Vue.use(Router)

const tenantConfig = loadTenantConfig()

function nestedRoute(name) { return { name: name, template: "<router-view/>" } }

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/login",
			name: "login",
			props: route => Object.assign({}, route.params, route.query),
			component: () => import(/* webpackChunkName: "login" */ `@/pages/login/${tenantConfig.pages.login}`)
		},
		{
			path: "/oauth",
			name: "oauth-callback",
			beforeEnter: oauthCallback
		},
		{
			path: "/refresh-password",
			name: "refresh-password",
			props: route => Object.assign({}, route.params, route.query),
			beforeEnter: (to, from, next) => {
				const username = to.params.username
				if(!!username) next()
				else next({ name: "login" })
			},
			component: () => import(/* webpackChunkName: "refresh-password" */ "@/pages/refresh-password")
		},
		{
			path: "/maintenance",
			name: "maintenance",
			component: () => import(/* webpackChunkName: "maintenance" */ "@/pages/maintenance")
		},
		{
			path: "/video-perizia/:hash",
			name: "video-perizia",
			props: true,
			component: () => import(/* webpackChunkName: "video-perizia" */ "@/pages/video-perizia")
		},
		{
			path: "/",
			meta: { isAuthenticated: true },
			component: () => import(/* webpackChunkName: "layout" */ "@/components/layout"),
			children: [
				{
					path: "",
					name: "home",
					component: () => import(/* webpackChunkName: "home" */ `@/pages/${tenantConfig.pages.home}`)
				},
				{
					path: "admin",
					meta: { roles: ["DEVELOPER", "CHIUSURA_CONVENZIONI"] },
					component: nestedRoute("admin"),
					children: [
						{
							path: "nais-1",
							name: "nais-1",
							component: () => import(/* webpackChunkName: "admin-nais-1" */ "@/pages/admin/nais-1/nais-1")
						},
						{
							path: "batch",
							meta: { roles: "BATCH" },
							component: nestedRoute("batch"),
							children: [
								{
									path: "",
									name: "elenco-batch",
									component: () => import(/* webpackChunkName: "batch" */ "@/pages/admin/batch/elenco-batch")
								},
								{
									path: ":id",
									name: "dettagli-batch",
									props: true,
									component: () => import(/* webpackChunkName: "batch" */ "@/pages/admin/batch/dettagli-batch")
								}
							]
						},
						{
							path: "scheduler",
							meta: { roles: "SCHEDULER" },
							component: nestedRoute("scheduler"),
							children: [
								{
									path: "",
									name: "elenco-scheduler",
									component: () => import(/* webpackChunkName: "scheduler" */ "@/pages/admin/scheduler/elenco-job-schedulati")
								}
							]
						},
						{
							path: "chiusura-convenzioni",
							name: "chiusura-convenzioni",
							meta: { roles: "CHIUSURA_CONVENZIONI" },
							component: () => import(/* webpackChunkName: "chiusura-convenzioni" */ "@/pages/admin/chiusura-convenzioni")
						},
						{
							path: "console",
							name: "console",
							meta: { roles: "GROOVY_CONSOLE" },
							component: () => import(/* webpackChunkName: "admin-console" */ "@/pages/admin/groovy-console")
						}
					]
				},
				{
					path: "configurazione",
					component: nestedRoute("configurazioni"),
					children: [
						{
							path: "nais-config",
							name: "nais-config",
							meta: { roles: "CONFIGURAZIONE_GLOBALE" },
							component: () => import(/* webpackChunkName: "nais-config" */ "@/pages/configurazione/nais-config/elenco-configurazioni")
						},
						{
							path: "marche",
							name: "elenco-marche",
							component: () => import(/* webpackChunkName: "admin-veicoli" */ "@/pages/configurazione/veicoli/elenco-marche")
						},
						{
							path: "geo",
							component: nestedRoute("geo"),
							children: [
								{
									path: "paesi",
									name: "elenco-paesi",
									component: () => import(/* webpackChunkName: "admin-geo" */ "@/pages/configurazione/geo/elenco-paesi")
								},
								{
									path: "province",
									name: "elenco-province",
									component: () => import(/* webpackChunkName: "admin-geo" */ "@/pages/configurazione/geo/elenco-province")
								},
								{
									path: "citta",
									name: "elenco-citta",
									component: () => import(/* webpackChunkName: "admin-geo" */ "@/pages/configurazione/geo/elenco-citta")
								},
								{
									path: "toponimi",
									name: "elenco-toponimi",
									component: () => import(/* webpackChunkName: "admin-geo" */ "@/pages/configurazione/geo/elenco-toponimi")
								}
							]
						},
						{
							path: "documenti",
							component: nestedRoute("documenti"),
							children: [
								{
									path: "compilabili",
									component: nestedRoute("compilabili"),
									meta: { roles: "DOCUMENTI_COMPILABILI" },
									children: [
										{
											path: "",
											name: "elenco-documenti-compilabili",
											component: () => import(/* webpackChunkName: "documenti-compilabili" */ "@/pages/configurazione/documenti/elenco-documenti-compilabili")
										},
										{
											path: "crea",
											name: "crea-documento-compilabile",
											component: () => import(/* webpackChunkName: "documenti-compilabili" */ "@/pages/configurazione/documenti/editor-documento-compilabile")
										},
										{
											path: ":id(\\d+)",
											name: "modifica-documento-compilabile",
											props: true,
											component: () => import(/* webpackChunkName: "documenti-compilabili" */ "@/pages/configurazione/documenti/editor-documento-compilabile")
										}
									]
								}
							]
						},
						{
							path: "antifurti",
							name: "elenco-antifurti",
							meta: { hasRoles: "DEVELOPER" },
							component: () => import(/* webpackChunkName: "antifurti" */ "@/pages/configurazione/antifurti/elenco-antifurti")
						},
						{
							path: "tariffe",
							meta: { hasRoles: "READ_TARIFFE" },
							component: nestedRoute("tariffe"),
							children: [
								{
									path: "",
									name: "elenco-tariffe",
									component: () => import(/* webpackChunkName: "tariffe" */ "@/pages/tariffe/elenco-tariffe")
								},
								{
									path: "crea",
									name: "nuova-tariffa",
									meta: { hasRoles: "CREATE_TARIFFE" },
									props: true,
									component: () => import(/* webpackChunkName: "tariffe" */ "@/pages/tariffe/editor-tariffa/")
								},
								{
									path: ":id(\\d+)",
									name: "modifica-tariffa",
									meta: { hasRoles: "UPDATE_TARIFFE" },
									props: true,
									component: () => import(/* webpackChunkName: "tariffe" */ "@/pages/tariffe/editor-tariffa/")
								},
							]
						},
						{
							path: "prodotti",
							meta: { hasRoles: "READ_PRODOTTI" },
							component: nestedRoute("prodotti"),
							children: [
								{
									path: "",
									name: "elenco-prodotti",
									component: () => import(/* webpackChunkName: "prodotti" */ "@/pages/prodotti/elenco-prodotti")
								},
								{
									path: "crea",
									name: "nuovo-prodotto",
									meta: { hasRoles: "CREATE_PRODOTTI" },
									component: () => import(/* webpackChunkName: "prodotti" */ "@/pages/prodotti/editor-prodotto/")
								},
								{
									path: ":id(\\d+)",
									name: "modifica-prodotto",
									meta: { hasRoles: "UPDATE_PRODOTTI" },
									props: true,
									component: () => import(/* webpackChunkName: "prodotti" */ "@/pages/prodotti/editor-prodotto/")
								}
							]
						},
						{
							path: "pacchetti",
							meta: { hasRoles: "READ_PACCHETTI" },
							component: nestedRoute("pacchetti"),
							children: [
								{
									path: "",
									name: "elenco-pacchetti",
									component: () => import(/* webpackChunkName: "pacchetti" */ "@/pages/pacchetti/elenco-pacchetti")
								},
								{
									path: "crea",
									name: "nuovo-pacchetto",
									meta: { hasRoles: "CREATE_PACCHETTI" },
									component: () => import(/* webpackChunkName: "pacchetti" */ "@/pages/pacchetti/editor-pacchetto")

								},
								{
									path: ":id(\\d+)",
									name: "modifica-pacchetto",
									meta: { hasRoles: "UPDATE_PACCHETTI" },
									props: true,
									component: () => import(/* webpackChunkName: "pacchetti" */ "@/pages/pacchetti/editor-pacchetto")
								},
							]
						},
						{
							path: "pacchetti-utente",
							meta: { hasRoles: "READ_PACCHETTI_UTENTE" },
							component: nestedRoute("pacchetti-utente"),
							children: [
								{
									path: "",
									name: "elenco-pacchetti-utente",
									component: () => import(/* webpackChunkName: "pacchetti-utente" */ "@/pages/pacchetti-utente/elenco-pacchetti-utente")
								},
								{
									path: "crea",
									name: "nuovo-pacchetto-utente",
									meta: { hasRoles: "CREATE_PACCHETTI_UTENTE" },
									component: () => import(/* webpackChunkName: "pacchetti-utente" */ "@/pages/pacchetti-utente/editor-pacchetto-utente")

								},
								{
									path: ":id(\\d+)",
									name: "modifica-pacchetto-utente",
									meta: { hasRoles: "UPDATE_PACCHETTI_UTENTE" },
									props: true,
									component: () => import(/* webpackChunkName: "pacchetti-utente" */ "@/pages/pacchetti-utente/editor-pacchetto-utente")
								},
							]
						},
						{
							path: "preset",
							meta: { hasRoles: "READ_PRESET" },
							component: nestedRoute("preset"),
							children: [
								{
									path: "",
									name: "elenco-preset",
									component: () => import(/* webpackChunkName: "preset" */ "@/pages/preset/elenco-preset")
								},
								{
									path: "crea",
									name: "nuovo-preset",
									meta: { hasRoles: "CREATE_PRESET" },
									component: () => import(/* webpackChunkName: "preset" */ "@/pages/preset/editor-preset")
								},
								{
									path: ":id(\\d+)",
									name: "modifica-preset",
									meta: { hasRoles: "UPDATE_PRESET" },
									props: true,
									component: () => import(/* webpackChunkName: "preset" */ "@/pages/preset/editor-preset")
								},
							]
						}
					]
				},
				{
					path: "analisi",
					component: nestedRoute("analisi"),
					children: [
						{
							path: "estrazioni",
							component: nestedRoute("estrazioni"),
							children: [
								//{
								//	path: "",
								//	name: "elenco-estrazioni",
								//	component: () => import(/* webpackChunkName: "estrazioni" */ "@/pages/analisi/estrazioni/elenco-estrazioni")
								//},
								{
									path: "crea",
									name: "nuova-estrazione",
									component: () => import(/* webpackChunkName: "estrazioni" */ "@/pages/analisi/estrazioni/editor-estrazione")
								},
								{
									path: ":id",
									name: "dettaglio-estrazione",
									props: route => ({ readonly: true, id: route.params.id }),
									component: () => import(/* webpackChunkName: "estrazioni" */ "@/pages/analisi/estrazioni/editor-estrazione")
								},
								{
									path: "modifica/:id",
									name: "modifica-estrazione",
									props: true,
									component: () => import(/* webpackChunkName: "estrazioni" */ "@/pages/analisi/estrazioni/editor-estrazione")
								}
							]
						},
						{
							path: "estrazioni-predefinite",
							component: nestedRoute("estrazioni-predefinite"),
							children: [
								{
									path: "blackbox-abbinate",
									name: "blackbox-abbinate",
									meta: { roles: "ESTRAZIONE_GAIA_GO" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/estrazione-gaia-go-abbinate")
								},
								{
									path: "clickdata",
									name: "estrazione-clickdata",
									meta: { roles: "ESTRAZIONE_CLICKDATA" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/estrazione-clickdata")
								},
								{
									path: "rinnovi-call-center",
									name: "estrazione-rinnovi-call-center",
									meta: { roles: "ESTRAZIONE_RINNOVI_CALL_CENTER" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/estrazione-rinnovi-call-center")
								},
								{
									path: "polizze-emesse",
									name: "estrazione-polizze-emesse",
									meta: { roles: "ESTRAZIONE_POLIZZE_EMESSE" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/estrazione-polizze-emesse")
								},
								{
									path: "formazione-ivass-in-scadenza",
									name: "estrazione-formazione-ivass-in-scadenza",
									meta: { roles: "ESTRAZIONE_FORMAZIONE_IVASS_IN_SCADENZA" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/formazione-ivass-in-scadenza/estrazione-formazione-ivass-in-scadenza")
								},
								{
									path: "moduli-proposta",
									name: "estrazioni-moduli-proposta",
									meta: { roles: "ESTRAZIONE_MODULO_PROPOSTA" },
									component: () => import(/* webpackChunkName: "estrazioni-predefinite" */ "@/pages/analisi/estrazioni-predefinite/moduli-proposta/moduli-proposta")
								}
							]
						}
					]
				},
				{
					path: "solleciti",
					component: nestedRoute("solleciti"),
					children: [
						{
							path: "formazione-ivass",
							name: "formazione-ivass-in-scadenza",
							meta: { roles: "FORMAZIONE_IVASS_IN_SCADENZA" },
							component: () => import(/* webpackChunkName: "solleciti-ivass" */ "@/pages/solleciti/formazione-ivass/elenco-formazione-ivass-in-scadenza")
						}
					]
				},
				{
					path: "trattative",
					meta: { roles: "READ_TRATTATIVE" },
					component: nestedRoute("trattative"),
					children: [
						{
							path: "",
							name: "elenco-trattative",
							component: () => import(/* webpackChunkName: "trattative" */ "@/pages/trattative/elenco-trattative")
						},
						{
							path: "crea",
							name: "nuova-trattativa",
							component: () => import(/* webpackChunkName: "trattative" */ "@/pages/trattative/editor-trattativa")
						},
						{
							path: "ricerca-avanzata",
							name: "ricerca-avanzata-trattative",
							component: () => import(/* webpackChunkName: "ricerca-trattative" */ "@/pages/trattative/ricerca-avanzata-trattative")
						},
						{
							path: ":id",
							name: "dettagli-trattativa",
							props: route => ({ readonly: true, id: route.params.id }),
							component: () => import(/* webpackChunkName: "trattative" */ "@/pages/trattative/editor-trattativa")
						},
						{
							path: "modifica/:id",
							name: "modifica-trattativa",
							props: true,
							component: () => import(/* webpackChunkName: "trattative" */ "@/pages/trattative/editor-trattativa")
						}
					]
				},
				{
					path: "preventivi",
					component: nestedRoute("preventivi"),
					children: [
						{
							path: "",
							meta: { roles: ["READ_PREVENTIVI"] },
							name: "elenco-preventivi",
							component: () => import(/* webpackChunkName: "preventivi" */ "@/pages/polizze/elenco-preventivi")
						},
						{
							path: "nuovo",
							meta: { roles: ["CREATE_PREVENTIVI"] },
							name: "preventivatore",
							props: true,
							component: () => import(/* webpackChunkName: "preventivatore" */ "@/pages/polizze/nuovo-preventivo")
						},
						{
							path: ":id",
							meta: { roles: ["READ_PREVENTIVI"] },
							name: "dettagli-preventivo",
							props: route => ({ readonly: true, id: route.params.id }),
							component: () => import(/* webpackChunkName: "preventivi" */ "@/pages/polizze/editor-preventivo")
						},
						{
							path: "completa/:id",
							meta: { roles: ["UPDATE_PREVENTIVI"] },
							name: "completa-preventivo",
							props: true,
							component: () => import(/* webpackChunkName: "preventivi" */ "@/pages/polizze/editor-preventivo")
						}
					]
				},
				{
					path: "preventivi-da-excel",
					meta: { roles: "PREVENTIVI_DA_EXCEL" },
					component: nestedRoute("preventivi-da-excel"),
					children: [
						{
							path: "",
							name: "elenco-preventivi-da-excel",
							component: () => import(/* webpackChunkName: "preventivi-da-excel" */ "@/pages/polizze/preventivi-da-excel/elenco-preventivi-da-excel")
						},
						{
							path: ":idRecord",
							name: "dettagli-preventivi-da-excel",
							props: true,
							component: () => import(/* webpackChunkName: "preventivi-da-excel" */ "@/pages/polizze/preventivi-da-excel/dettagli-preventivi-da-excel")
						}
					]
				},
				{
					path: "polizze",
					component: nestedRoute("polizze"),
					children: [
						{
							path: "",
							meta: { roles: ["READ_POLIZZE"] },
							name: "elenco-polizze",
							component: () => import(/* webpackChunkName: "polizze" */ "@/pages/polizze/elenco-polizze")
						},
						{
							path: "/pratiche-rinnovo",
							meta: { roles: ["READ_PRATICHE_RINNOVO"] },
							component: nestedRoute("pratiche-rinnovo"),
							children: [
								{
									path: "",
									name: "elenco-pratiche-rinnovo",
									component: () => import(/* webpackChunkName: "pratiche-rinnovo" */ "@/pages/polizze/pratiche-rinnovo/elenco-pratiche")
								},
								{
									path: "abbina",
									name: "abbina-pratiche-rinnovo",
									meta: { roles: ["RESPONSABILE_CALL_CENTER"] },
									component: () => import(/* webpackChunkName: "pratiche-rinnovo" */ "@/pages/polizze/pratiche-rinnovo/abbina-pratiche")
								},
								{
									path: "rinnova",
									meta: { roles: ["CREATE_RINNOVI"] },
									name: "preventivatore-rinnovo",
									props: true,
									component: () => import(/* webpackChunkName: "preventivatore-rinnovo" */ "@/pages/polizze/nuovo-preventivo")
								},
								{
									path: ":id(\\d+)",
									name: "dettagli-pratica-rinnovo",
									props: true,
									component: () => import(/* webpackChunkName: "pratiche-rinnovo" */ "@/pages/polizze/pratiche-rinnovo/dettagli-pratica")
								}
							]
						},
						{
							path: ":id(\\d+)",
							name: "dettagli-polizza",
							props: route => ({ readonly: true, id: route.params.id, polizza: true }),
							component: () => import(/* webpackChunkName: "polizze" */ "@/pages/polizze/editor-preventivo")
						}
					]
				},
				{
					path: "video-perizie",
					meta: { roles: "READ_VIDEO_PERIZIA" },
					component: nestedRoute("video-perizie"),
					children: [
						{
							path: "",
							name: "elenco-video-perizie",
							component: () => import(/* webpackChunkName: "video-perizie" */ "@/pages/video-expertise/elenco-video-perizie")
						}
					]
				},
				{
					path: "sinistri",
					meta: { roles: "READ_SINISTRI" },
					component: nestedRoute("sinistri"),
					children: [
						{
							path: "",
							name: "elenco-sinistri",
							component: () => import(/* webpackChunkName: "sinistri" */ "@/pages/sinistri/elenco-sinistri")
						},
						{
							path: ":codice",
							name: "dettagli-sinistro",
							props: true,
							component: () => import(/* webpackChunkName: "sinistri" */ "@/pages/sinistri/dettagli-sinistro")
						}
					]
				},
				{
					path: "tracciati",
					meta: { roles: ["TRACCIATI_POLIZZA"] },
					component: nestedRoute("tracciati"),
					children: [
						{
							path: "",
							name: "elenco-tracciati",
							component: () => import(/* webpackChunkName: "tracciati" */ "@/pages/tracciati/elenco-tracciati")
						},
						{
							path: ":id(\\d+)",
							name: "dettagli-tracciato",
							props: true,
							component: () => import(/* webpackChunkName: "tracciati" */ "@/pages/tracciati/dettagli-tracciato")
						}
					]
				},
				{
					path: "richieste-modifica",
					meta: { roles: ["READ_RICHIESTE_MODIFICA_POLIZZA"] },
					component: nestedRoute("richieste-modifica"),
					children: [
						{
							path: "",
							name: "elenco-richieste-modifica-polizza",
							component: () => import(/* webpackChunkName: "richieste-modifica" */ "@/pages/polizze/richieste-modifica/elenco-richieste-modifica-polizza")
						},
						{
							path: ":ticket(\.+)",
							name: "dettagli-richieste-modifica-polizza",
							props: true,
							component: () => import(/* webpackChunkName: "richieste-modifica" */ "@/pages/polizze/richieste-modifica/dettagli-richiesta-modifica-polizza")
						}
					]
				},
				{
					path: "annullamenti",
					meta: { roles: ["ANNULLAMENTI"] },
					component: nestedRoute("annullamenti"),
					children: [
						{
							path: "",
							name: "elenco-annullamenti",
							component: () => import(/* webpackChunkName: "annullamenti" */ "@/pages/polizze/annullamenti/elenco-annullamenti")
						},
						{
							path: "nuovo/:ticket(\.+)",
							name: "nuovo-annullamento",
							props: true,
							component: () => import(/* webpackChunkName: "annullamenti" */ "@/pages/polizze/annullamenti/editor-annullamento")
						},
						{
							path: ":id(\\d+)",
							name: "modifica-annullamento",
							props: true,
							component: () => import(/* webpackChunkName: "annullamenti" */ "@/pages/polizze/annullamenti/editor-annullamento")
						},
						{
							path: "dettagli/:id(\\d+)",
							name: "dettagli-annullamento",
							props: route => ({ readonly: true, id: route.params.id }),
							component: () => import(/* webpackChunkName: "annullamenti" */ "@/pages/polizze/annullamenti/editor-annullamento")
						}
					]
				},
				{
					path: "variazioni",
					meta: { roles: ["VARIAZIONI"] },
					name: "modifica-polizza",
					props: true,
					component: () => import(/* webpackChunkName: "modifica-polizza" */ "@/pages/polizze/variazioni/modifica-polizza")
					//component: nestedRoute("variazioni"),
					//children: [
					//	{
					//		path: ":ticket(\.+)",
					//		name: "modifica-polizza",
					//		props: true,
					//		component: () => import(/* webpackChunkName: "modifica-polizza" */ "@/pages/polizze/variazioni/modifica-polizza")
					//	}
					//]
				},
				{
					path: "clienti",
					component: nestedRoute("clienti"),
					children: [
						{
							path: "",
							meta: { roles: ["READ_POLIZZE", "READ_PREVENTIVI"] },
							name: "elenco-clienti",
							component: () => import(/* webpackChunkName: "polizze" */ "@/pages/polizze/elenco-clienti")
						}
					]
				},
				{
					path: "estratti-conto",
					meta: { roles: ["READ_ESTRATTI_CONTO"] },
					component: nestedRoute("estratti-conto"),
					children: [
						{
							path: "",
							name: "elenco-estratti-conto",
							component: () => import(/* webpackChunkName: "estratti-conto" */ "@/pages/econto/elenco-estratti-conto")
						},
						{
							path: "logs/:dataInserimento",
							name: "elenco-logs-estratti-conto",
							props: true,
							meta: { roles: ["UPDATE_ESTRATTI_CONTO"] },
							component: () => import(/* webpackChunkName: "estratti-conto" */ "@/pages/econto/elenco-logs-estratti-conto")
						}
					]
				},
				{
					path: "utenti",
					meta: { roles: "READ_UTENTI" },
					component: nestedRoute("utenti"),
					children: [
						{
							path: ":userPath?",
							name: "elenco-utenti",
							props: true,
							component: () => import(/* webpackChunkName: "utenti" */ "@/pages/utenti/elenco-utenti")
						},
						{
							path: ":userPath/crea",
							name: "nuovo-utente",
							props: true,
							meta: { roles: "CREATE_UTENTI" },
							component: () => import(/* webpackChunkName: "utenti" */ "@/pages/utenti/editor-utente")
						},
						{
							path: ":userPath/:id(\\d+)",
							name: "modifica-utente",
							props: true,
							meta: { roles: "UPDATE_UTENTI" },
							component: () => import(/* webpackChunkName: "utenti" */ "@/pages/utenti/editor-utente")
						},
						{
							path: "login-as/:username",
							name: "login-as",
							beforeEnter: loginAsCallback
						}
					]
				},
				{
					path: "contacts",
					meta: { roles: "READ_CONTATTI" },
					component: nestedRoute("contacts"),
					children: [
						{
							path: "",
							name: "contacts-info",
							component: () => import("@/pages/contacts/contacts-info")
						}
					]
				},
				{
					path: "account",
					name: "account",
					component: () => import(/* webpackChunkName: "account" */ "@/pages/utenti/account-utente")
				},
				{
					path: "unauthorized",
					name: "unauthorized",
					component: () => import(/* webpackChunkName: "errors" */ "@/pages/unauthorized")
				},
				{
					path: "not-found",
					name: "not-found",
					component: () => import(/* webpackChunkName: "errors" */ "@/pages/not-found")
				}
			]
		}
	]
})

router.beforeEach(beforeRouteEnter)

export default router